import logo from './logo.svg';
import './App.css';
import Login from './Pages/Login';
import Sidebar from './Compones/Sidebar';

function App() {
  return (
    <div className="">
      <Login />
    </div>
  );
}

export default App;
